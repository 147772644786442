<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback2 />
        {{ $t('MedicineEquipmentSummary') }}
        <v-spacer></v-spacer>
        <v-btn color='primary' class='d-none d-md-block' @click='exportExcel'
               :loading='exportLoading' :disabled='exportLoading'>
          {{ $t('excel') }}
        </v-btn>
        <v-btn color='primary' class='d-block d-md-none' icon fab outlined @click='exportExcel'
               :loading='exportLoading' :disabled='exportLoading'>
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col cols='12' md='6' lg='3' class='py-0' v-if='store.state.shopMainStatus'>
          <v-select
            v-model.trim='branchSelection'
            :items='branchList'
            outlined
            @change='addPayload'
            dense
            :label="$t('select_branch')"
            item-text='shop_name'
            :no-data-text="$t('no_data')"
            item-value='shop_id_pri'
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-select
            v-model.trim='drug_category_id'
            :items='drugTypeList'
            :label="$t('type')"
            dense
            outlined
            :item-text='$i18n.locale'
            item-value='value'
            @change='addPayload()'
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-dialog ref='datePickerStart' v-model.trim='isShowDate1' :return-value.sync='dateStart'
                    width='290px'>
            <template v-slot:activator='{ on, attrs }'>
              <v-text-field
                v-model.trim='dateStart'
                :label="$t('date')"
                outlined
                dense
                readonly
                v-bind='attrs'
                v-on='on'
              >
              </v-text-field>
            </template>
            <v-date-picker v-model.trim='dateStart' :locale='$i18n.locale' scrollable>
              <v-spacer></v-spacer>
              <v-btn text color='primary' @click='$refs.datePickerStart.save(dateStart)'>
                {{ $t('confirm') }}
              </v-btn>
              <v-btn text color='secondary' @click='isShowDate1 = false'>
                {{ $t('cancel') }}
              </v-btn>

              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-dialog ref='datePickerEnd' v-model.trim='isShowDate2' :return-value.sync='dateEnd'
                    width='290px'>
            <template v-slot:activator='{ on, attrs }'>
              <v-text-field
                v-model.trim='dateEnd'
                :label="$t('to')"
                readonly
                outlined
                dense
                v-bind='attrs'
                @change='addPayload()'
                v-on='on'
              >
                <template v-slot:append>
                  <v-tooltip color='#212121' top>
                    <template v-slot:activator='{ on, attrs }'>
                      <v-icon
                        color='primary'
                        v-bind='attrs'
                        v-on='on'
                        @click="
                          dateStart = ''
                          dateEnd = ''
                        "
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t('all_dates') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model.trim='dateEnd' :locale='$i18n.locale' scrollable>
              <v-spacer></v-spacer>

              <v-btn text color='primary' @click='$refs.datePickerEnd.save(dateEnd)'>
                {{ $t('confirm') }}
              </v-btn>
              <v-btn text color='secondary' @click='isShowDate2 = false'>
                {{ $t('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-text-field
            v-model.trim='searchtext'
            dense
            outlined
            :label="`${$t('search')} ${$t('pressEnterForSearch')}`"
            @keypress.enter='addPayload()'
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        item-key='number'
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.code`]='{ item }'>
          <router-link
            v-if="item.type == $t('receipt')"
            :to="{ name: 'order-detail', params: { id: item.id } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.code }}
          </router-link>
          <router-link
            v-if="item.type == $t('course_services')"
            :to="{ name: 'showDetailService', params: { id: item.id } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.code }}
          </router-link>
          <router-link
            v-if="item.type == $t('transfer_out')"
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'DetailTransferOut', params: { id: item.id } }"
          >
            {{ item.code }}
          </router-link>
        </template>
        <template v-slot:[`item.druglot_name`]='{ item }'>
          <span v-if='item.druglot_name'> {{ item.druglot_date | sumdate }} ({{ item.druglot_name
            }}) </span>
        </template>
        <template v-slot:[`item.name`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.date`]='{ item }'>
          {{ item.date | sumdatetime }}
        </template>
        <template v-slot:[`item.amount`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.amount }}
          </span>
        </template>
        <template v-slot:[`item.price`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.price }}
          </span>
        </template>
        <template v-slot:[`item.discount`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.discount }}
          </span>
        </template>
        <template v-slot:[`item.total`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.total }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { mdiCalendarBlank, mdiFileExcelOutline } from '@mdi/js'
import Comeback2 from '../Comeback2.vue'
import { i18n } from '@/plugins/i18n'
import { reportDrug2, removeComma } from '../useExcel2'
import { sumdate, sumdatetime } from '@/plugins/filters'
import drugTypeForSearch from '@/@fake-db/data/drugTypeForSearch.json'
import branch from '@/api/branch'
import store from '@/store/app'

export default {
  components: {
    Comeback2,
  },
  filters: {
    sumdate,
    sumdatetime,
  },
  setup() {
    const XLSX = require('xlsx')
    const moment = require('moment')
    const branchSelection = ref('')
    const branchList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('type'), value: 'type', width: 120 },
      { text: i18n.t('branch'), value: 'shop_name', width: 200 },
      { text: i18n.t('a_number'), value: 'code', width: 130 },
      { text: i18n.t('date'), value: 'date', width: 180 },
      { text: i18n.t('group'), value: 'drug_category_name', width: 50 },
      { text: `${i18n.t('Around the stock')}`, value: 'druglot_name', width: 180 },
      { text: i18n.t('durg_saler'), value: 'drugfrom_name', width: 180 },
      { text: i18n.t('code'), value: 'main_drug_id', width: 100 },
      { text: i18n.t('name'), value: 'name', width: 210 },
      {
        text: i18n.t('amount'),
        value: 'amount',
        width: 100,
        align: 'end',
      },
      { text: i18n.t('unit'), value: 'unit', width: 100 },
      {
        text: i18n.t('sale_price'),
        value: 'price',
        width: 210,
        align: 'end',
      },
      {
        text: i18n.t('discount'),
        value: 'discount',
        width: 210,
        align: 'end',
      },
      {
        text: i18n.t('net_amount'),
        value: 'total',
        width: 210,
        align: 'end',
      },
    ])
    const dataTableList = ref([])
    const options = ref({})
    const payload = ref({})
    const loading = ref(true)
    const header = ref('')
    const searchtext = ref('')
    const exportLoading = ref(false)
    const drugTypeList = ref(drugTypeForSearch.data)
    const drug_category_id = ref('')
    const dateStart = ref(moment(new Date()).format('YYYY-MM-DD'))
    const dateEnd = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const customerList = ref([])

    branch
      .branchListForSelection()
      .then(res => {
        branchSelection.value = res[0].shop_id_pri
        branchList.value = res
        addPayload()
      })
      .catch(error => {
        console.log('error : ', error)
      })

    const addPayload = () => {
      payload.value = {
        start: dateStart.value,
        end: dateEnd.value,
        searchtext: searchtext.value,
        main_shop_id_pri: branchSelection.value,
        drug_category_id: drug_category_id.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      let number = 1
      const { start, end } = payload
      const { data, customerData } = await reportDrug2(payload)
      dataTableList.value = data
      customerList.value = customerData.map((item, i) => {
        item.customer_address = `${item.customer_address} ${item.customer_district}  ${item.customer_amphoe} ${item.customer_province} ${item.customer_zipcode}`
        delete item.customer_district
        delete item.customer_amphoe
        delete item.customer_province
        delete item.customer_zipcode
        delete item.drugfrom_name
        delete item.id
        delete item.druglot_date
        delete item.druglot_name
        delete item.customer_id_pri
        item.tags = item.tags ? String(item.tags) : ''
        return { number: number++, ...item }
      })
      header.value = `${i18n.t('report_drug_equipment_summary')}  ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)}`
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      let dataExport = dataTableList.value.map(item => {
        delete item.id
        delete item.druglot_name
        delete item.druglot_date
        delete item.main_drug_id

        return item
      })
      dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))
      const fileName = `${header.value}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  สรุปยอดขายยา/อุปกรณ์ */
      const Heading = [
        [`${header.value}`],
        [
          '#',
          i18n.t('type'),
          i18n.t('branch'),
          i18n.t('a_number'),
          i18n.t('date'),
          i18n.t('group'),
          `${i18n.t('Around the stock')}`,
          i18n.t('durg_saler'),
          i18n.t('name'),
          i18n.t('amount'),
          i18n.t('unit'),
          i18n.t('sale_price'),
          i18n.t('discount'),
          i18n.t('net_amount'),
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'type',
          'shop_name',
          'code',
          'date',
          'drug_category_name',
          'drug_id',
          'drugfrom_name',
          'name',
          'amount',
          'unit',
          'price',
          'discount',
          'total',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }]
      const wscols = [{ wch: 6 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, i18n.t('Medicine Equipment Summary').replace('/', '_'))

      /*  รายชื่อลูกค้าตามยอดขาย */
      const Heading2 = [
        [`${i18n.t('list_of_customers_by_sales')} `],
        [
          '#',
          i18n.t('a_number'),
          i18n.t('type'),
          i18n.t('category'),
          i18n.t('banch'),
          i18n.t('code'),
          i18n.t('drug_name'),
          i18n.t('lot'),
          i18n.t('customer_code'),
          i18n.t('name'),
          i18n.t('tel'),
          i18n.t('group_customers'),
          i18n.t('id_card'),
          i18n.t('gender'),
          i18n.t('email'),
          i18n.t('blood_type'),
          i18n.t('birthday'),
          i18n.t('old'),
          i18n.t('address'),
          i18n.t('tag'),
          i18n.t('amount'),
          i18n.t('unit'),
          i18n.t('discount'),
          i18n.t('price_unit'),
          i18n.t('total_price'),
          i18n.t('date'),
          i18n.t('user_is_list'),
        ],
      ]

      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, await removeComma(JSON.parse(JSON.stringify(customerList.value))), {
        header: [
          'number',
          'shop_name',
          'code',
          'type',
          'drug_category_name',
          'main_drug_id',
          'name',
          'drug_id',
          'customer_id',
          'customer_name',
          'customer_tel',
          'customer_group_name',
          'customer_idcard',
          'customer_gender',
          'customer_email',
          'customer_blood',
          'customer_birthdate',
          'customer_age',
          'customer_address',
          'tags',
          'amount',
          'unit',
          'discount',
          'price',
          'total',
          'date',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 18 } }]
      const wscols2 = [
        { wch: 6 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2

      /* พิมพ์ files */
      XLSX.utils.book_append_sheet(wb, ws2, `${i18n.t('customer')}`)
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    watch([dateStart, dateEnd], () => {
      addPayload()
    })

    return {
      loading,
      dataTableList,
      columns,
      options,
      header,
      drugTypeList,
      searchtext,
      drug_category_id,
      dateStart,
      dateEnd,
      isShowDate1,
      isShowDate2,
      addPayload,
      exportLoading,
      serachReport,
      store,
      exportExcel,
      branchSelection,
      branchList,
      mdiFileExcelOutline,
      icons: {
        mdiCalendarBlank,
      },
    }
  },
}
</script>
